body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

/* .navLinks li:hover{
  background-color: gray;
} */

.logo{
  height: 100px;
  margin-right: 10px;
}

.hero-bg{
  
  background-image:linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)), url('../src/components/images/proTacos.jpg');
  background-size: cover;
  /* background-position: 0% 80%; */
  height: 50vh;
}


.footer{
  position: relative;
  bottom: 0;
  width: 100%;
}

.menuTitle{
  font-size: 50px;
  font-weight: bolder;
}
.categoriesContainer{
  width: 90%;
  margin: auto;
}
.categoryBox{
  margin: 20px 0;
  background-color: rgb(238, 235, 231);
}
.categoryBox > h2{
  color: darkorange;
  text-align: center;
  font-style: italic;
}

.itemCard > h4{
  color: crimson;
}
.itemCard{
  border-bottom: 1px solid lightgrey;
}

.itemsContainer{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.foodItemCard{
  margin: 10px 5px;
  padding: 8px;
  /* border: 1px solid red; */
  border-radius: 15px;
  background-color: rgb(156, 155, 155, .2);
}
.foodItemCard:hover{
  /* background-color: rgb(220, 20, 60, 0.2); */
  border: 1px solid rgba(153, 8, 8, .5);
  box-shadow: -9px 10px 5px -6px rgba(153, 8, 8, .2);
}
.foodItemCard > h4{
  color: rgb(204, 15, 15);
}

.breakfastSec{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}


.img-side img{
  height: 370px;
  width: 300px;
  object-fit: cover;
  border: 2px solid black;
}

.gallery-container{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 1rem;
  /* grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1rem; */
}


.gallery-img{
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.social-img{
  height: 150px;
  border-radius: 20px;
  margin: 10px 5px;
}

@media screen and (min-width: 992px){
  .hero-bg{
    height: 80vh;
    background-position: 0% 90%;
  }
}